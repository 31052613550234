import { Table, TableContainer } from '@odin-labs/components';
import React, { ReactElement } from 'react';
import { ContractorPaymentMethod, ContractorTabProps } from 'containers/contractor/types';
import { useGetContractorContainerPaymentMethodsQuery } from 'apollo/generated/client-operations';
import { getPaymentMethodsColumns } from 'containers/contractor/helpers/tables';

export function ContractorPaymentMethodsTabContainer(props: ContractorTabProps): ReactElement {
  const { contractorId, onTabApiChange } = props;

  const {
    data,
    loading,
    refetch: refetchPaymentMethods,
  } = useGetContractorContainerPaymentMethodsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      contractorId,
      includeArchived: true,
    },
  });

  const paymentMethods = React.useMemo(
    () =>
      (data?.getContractor?.stripePaymentMethods ?? []).map((spm) => {
        const matching = data?.getContractor?.jobsiteContractors?.edges?.filter(
          (jc) => jc.node.stripePaymentMethodId === spm.id,
        );
        return {
          ...spm,
          jobsites: matching?.map((jc) => jc.node.jobsite),
        } as ContractorPaymentMethod;
      }),
    [data],
  );
  const columns = getPaymentMethodsColumns();

  const paymentMethodsCount = paymentMethods?.length?.toString();
  React.useEffect(
    () => onTabApiChange({ refetchData: refetchPaymentMethods, paymentMethodsCount }),
    [refetchPaymentMethods, paymentMethodsCount],
  );

  return (
    <div id="payment-methods-container">
      <TableContainer className="contractor-payment-methods-container">
        <Table loading={loading} columns={columns} data={paymentMethods} />
      </TableContainer>
    </div>
  );
}
